import React from 'react'
import PresetSentencesPage from '../../../components/pages/StarTutoring/PresetSentencesPage'
import StarTutoringLayout from '../../../components/pages/StarTutoring/StarTutoringLayout'

const Page = (props: any) => {
  return <StarTutoringLayout path={props.path}>
    <PresetSentencesPage />
  </StarTutoringLayout>
}

export default Page
