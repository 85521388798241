import React, { useMemo } from 'react'
import styled from 'styled-components';
import { IconButton, Pencil, Plus, Refresh, TrashWhite } from 'nzk-react-components';
import { navigate } from 'gatsby';
import useData from '../data/PresetSentences/useData';
import useAsync from '../../../../hooks/useAsync';
import Table from '../../../TableV2';
import useConfirmModal from '../../../../hooks/useConfirmModal';

const Wrapper = styled.div``

const Header = styled.div`
  display: flex;
  margin-bottom: 10px;
  > * { margin-right: 10px; }
`

const Actions = styled.div`
  display: flex;
  justify-content: flex-start;
  > * { margin-right: 10px; }
  > :last-child { margin-right: 0; }
`

const PresetSentencesPage = () => {
  const { fetchPresetSentences, deletePresetSentence } = useData()

  const { data: sentences, loading, execute: fetch } = useAsync({
    asyncFunc: async (params) => {
      return fetchPresetSentences(params.skip, params.limit, params.refetch)
    },
    funcParams: {
      skip: 0,
      limit: 0,
      refetch: false
    },
    immediate: true,
    initialData: []
  })

  const confirmDelete = useConfirmModal({
    title: "Are you sure?",
    message:
      "Do you want to delete this preset sentence?",
    onConfirm: async (id) => {
      await deletePresetSentence(id)
      fetch({ refetch: true })
    },
    onCancel: () => {},
  })

  const formattedSentences = useMemo(() => {
    return sentences
  }, [sentences])

  const columns = useMemo(() => {
    return [
      {
        'Header': 'Title',
        'accessor': 'title',
        disableFilters: true,
      },
      {
        Header: 'Actions',
        disableFilters: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }: any) => <Actions>
          <IconButton icon={<TrashWhite />} size='x-small' theme='red' onClick={() => confirmDelete(row.original._id)}>Delete</IconButton>
          <IconButton icon={<Pencil />} size='x-small' theme='orange' onClick={() => navigate(`/star-tutoring/preset-sentences/${row.original._id}/edit`)}>Edit</IconButton>
        </Actions>
      }
    ]
  }, [formattedSentences])

  return <Wrapper>
    <Header>
      <IconButton size='x-small' theme='primary' icon={<Refresh />} onClick={() => fetch({ refetch: true })}>Refresh</IconButton>
      <IconButton size='x-small' theme='confirm' icon={<Plus />} onClick={() => navigate('/star-tutoring/preset-sentences/create')}>Create</IconButton>
    </Header>
    <Table columns={columns} data={formattedSentences} loading={loading} />
  </Wrapper>
}

export default PresetSentencesPage
